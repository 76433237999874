import axios from 'axios';

// Create axios instance
const service = axios.create({
  timeout: 60000, // Request timeout
  headers: {
    Authorization: 'Bearer ' + process.env.VUE_APP_PUBLIC_TOKEN,
  },
});
// service.defaults.withCredentials = true;

// Request interceptor
service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  },
);

// response pre-processing
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  },
);

export default service;

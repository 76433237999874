<template>
  <div>
    <div class="code-step">
      <el-row>
        <el-col>
          <help-tooltip :content="$tc('codeStepHelp')" />
        </el-col>
      </el-row>
      <el-row
        v-if="form.generalStep.customerId !== ''"
        :gutter="15"
        class="m-b-10"
      >
        <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
          <Prism language="javascript">
            {{ code }}
          </Prism>
        </el-col>
      </el-row>
    </div>
    <el-row type="flex" justify="end">
      <el-button
        type="default"
        icon="el-icon-arrow-left"
        class="pull-right"
        @click="prev"
      >{{ $t("previousStep") }}
      </el-button>
    </el-row>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import DivCode from './../services/DivCode';
import HelpTooltip from './HelpTooltip';

export default {
  name: 'CodeStep',
  components: { HelpTooltip, Prism },
  props: {
    form: {
      type: Object,
      default() {
        return {};
      },
    },
    activeTabStart: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    code: function() {
      return DivCode.create(this.form, this.activeTabStart);
    },
  },
  methods: {
    prev() {
      this.$emit('prev');
      this.$emit('changed', this.form);
    },
  },
};
</script>

<template>
  <el-form
    ref="generalForm"
    :model="form"
    label-position="left"
    class="m-b-10"
  >
    <div class="general-step">
      <el-row>
        <el-col>
          <help-tooltip :content="$tc('generalStepHelp')" />
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-row :gutter="15">
            <el-col :xl="8" :lg="8" :md="8" :sm="12" :xs="24">
              <el-form-item
                :label="$tc('partnerId')"
                prop="customerId"
                :rules="rules.customerId"
              >
                <el-input
                  v-model="form.customerId"
                  :placeholder="$t('partnerId')"
                />
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$tc('channel')">
                <el-input
                  v-model="form.channel"
                  :placeholder="$tc('channel')"
                />
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$tc('shop')" prop="shopSelected">
                <el-select
                  v-model="form.shopSelected"
                  :placeholder="$tc('select')"
                  class="w-100"
                >
                  <el-option
                    v-for="item in shops"
                    :key="item.id"
                    :label="item.value"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="15">
            <el-col :xl="8" :lg="8" :md="8" :sm="12" :xs="24">
              <el-form-item :label="$tc('cruiseType')">
                <el-select
                  v-model="form.cruiseType"
                  :placeholder="$tc('select')"
                  class="w-100"
                >
                  <el-option
                    v-for="item in cruiseTypes"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xl="8" :lg="8" :md="8" :sm="12" :xs="24">
              <el-form-item class="m-t-35">
                <el-switch
                  v-model="showSearch"
                  :active-text="$tc('showSearch')"
                  name="showSearch"
                />
                <el-switch
                  v-model="showEyecatcher"
                  class="m-l-35"
                  :active-text="$tc('showEyecatcher')"
                  name="showEyecatcher"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>

    <el-row type="flex" justify="end">
      <el-button
        class="pull-right"
        type="primary"
        @click="next"
      >{{ $t("nextStep") }}<i
        class="el-icon-arrow-right"
      /></el-button>
    </el-row>
  </el-form>
</template>
<script>
import HelpTooltip from './HelpTooltip';
import HostNameService from './../services/HostNameService';

export default {
  name: 'GeneralStep',
  components: { HelpTooltip },
  props: {
    form: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: function() {
    return {
      showSearch: true,
      showEyecatcher: true,
      shops: [
        { id: 'de', value: 'meine-landausfluege.de' },
        { id: 'ch', value: 'meine-landausfluege.ch' },
      ],
      cruiseTypes: [
        { id: 'all', label: this.$i18n.t('allCruises') },
        { id: 'sea', label: this.$i18n.t('onlySea') },
        { id: 'river', label: this.$i18n.t('onlyRiver') },
      ],
      rules: {
        customerId: [
          {
            required: true,
            trigger: 'change',
            message: this.$i18n.t('partnerIdRequiredMessage'),
          },
        ],
      },
    };
  },
  watch: {
    showSearch(value) {
      if (value && !this.form.components.find(element => element === 'search')) {
        this.form.components.unshift('search');
      } else {
        if (this.form.components.find(element => element === 'search')) {
          this.form.components = this.form.components.filter(e => e !== 'search');
        }
      }
    },
    showEyecatcher(value) {
      this.form.showEyecatcher = value;
    },
    'form.shopSelected': async function(value) {
      HostNameService.setShop(value);
    },
  },
  methods: {
    next() {
      this.$refs.generalForm.validate((valid) => {
        if (valid) {
          this.$emit('next');
          this.$emit('changed', this.form);
        }
      });
    },
  },
};
</script>

<template>
  <el-container class="widget-generator-main">
    <el-main>
      <el-row class="m-b-20">
        <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
          <el-steps :active="active" finish-status="success">
            <el-step :title="$t('general')" />
            <el-step :title="$t('startDates')" />
            <el-step :title="$t('filter')" />
            <el-step :title="$t('code')" />
          </el-steps>
        </el-col>
      </el-row>

      <form-wizard :step.sync="active" :default-customer-id="defaultCustomerId" @changed="onChange" />

      <widget-preview :preview-data="previewData" />
    </el-main>
  </el-container>
</template>

<script>
import FormWizard from './FormWizard';
import WidgetPreview from './WidgetPreview';

export default {
  name: 'Main',
  components: { WidgetPreview, FormWizard },
  props: {
    defaultCustomerId: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: function() {
    return {
      active: 0,
      previewData: {},
    };
  },
  methods: {
    onChange(data) {
      this.previewData = data;
    },
  },
};
</script>

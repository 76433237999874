export default {
  'el.select.noData': 'no data',

  select: 'Select',
  previousStep: 'Previous Step',
  nextStep: 'Next Step',

  /* header  */
  widgetHeader: 'Widget Code Generator for My Cruise Excursion',
  imprint: 'Imprint',
  dataProtection: 'Data Protection',
  simpleMethodDescription:
    'The "simple" method creates an IFrame tag that can be included in every page.This variant allows a simple and secure implementation, only an optical adaptation is difficult.',
  pleaseContactUsShort:
    'Please contact us, if you do not have a <b>partner ID</b> or questions.',

  /* main  */
  general: 'General',
  startDates: 'Start dates',
  filter: 'Filter',
  code: 'Code',

  /* form wizard  */
  selectedFieldsRequiredMessage: 'Please enter required fields',

  /* general step  */
  partnerId: 'Partner ID',
  shop: 'Shop',
  channel: 'Channel',
  cruiseType: 'Cruise type',
  allCruises: 'All cruises',
  onlySea: 'Only sea',
  onlyRiver: 'Only river',
  showSearch: 'Show search',
  showEyecatcher: 'Show eyecatcher',
  partnerIdRequiredMessage: 'Partner ID is required',

  /* start dates step  */
  portSearch: 'Port',
  cruiseSearch: 'Cruise',
  destinationSearch: 'Destination',
  productsSearch: 'Products',
  destination: 'Destination',
  portSelect: 'Select Port',
  portsSelect: 'Select Ports',
  cruiseCompanySelect: 'Select Cruise Company',
  cruiseShipSelect: 'Select Ship',
  cruiseSelect: 'Select Cruise',
  productsSelect: 'Select Products',
  days: 'Days',

  /* filter step  */
  limit: 'Product Limit',
  shortProducts: 'Show only a few products (3 - 5) and reload more with one click',
  top: 'Top',
  sale: 'Sale',
  new: 'New',
  private: 'Private',
  onYourOwn: 'On your own',
  tags: 'Only excursions with the label:',

  /* widget preview  */
  preview: 'Preview',

  /* help tooltip */
  generalStepHelp:
    '<p>Here you can make the general settings.</p> ' +
    '<p><b>Partner ID</b>:</p>' +
    '<p>- You will get your partners from our partner department once you have signed a contract. Example ID: TA123456. Please enter a complete ID starting with "TA".<p>' +
    '<p><b>Channels</b>:</p>' +
    '<p>- Here you can freely enter any abbreviation in order to be able to see exactly which bookings were generated via this widget in your statements and booking statistics at the end. This marking is recommended if you have integrated several links and/or also work with banners or our booking link dispatch.</p>' +
    '<p><b>Shop</b>:</p>' +
    '<p>- If you run a website in Switzerland and need the prices in CHF, you can set the Swiss domain here.</p>' +
    '<p><b>Cruise Type</b>:</p>' +
    '<p>- We offer excursions for deep sea and river cruises. You can limit the widget to a specific type. Only certain cruises or ports are displayed in the widget search.</p>' +
    '<p><b>Show search</b>:</p>' +
    '<p>- If you click "Show search", an additional search field will appear above your selected widget start page (e.g. specific port or route). Irrespective of the choice of your start page, the customer can select further routes and ports for shore excursions at any time in the upper search form.</p>',
  startDateStepHelp:
    '<p>You can choose between 3 different start pages. Irrespective of the choice of your start page, your customer can select further routes and ports at any time in the upper search form if you have clicked on the search field under "General".<p>' +
    '<p><b>Harbor</b>:</p>' +
    '<p>- Only excursions to a specific port are displayed.</p>' +
    '<p>- This widget home page is primarily recommended for a home page that is divided into different port pages, so that you can integrate the appropriate port widget for each port page and pick up the customer thematically best.</p>' +
    '<p><b>Cruise</b>:</p>' +
    '<p>- Only excursions related to a specific cruise route are displayed.</p>' +
    '<p>- This setting is best suited to focus on a top selling route. In addition, the integration on a website with the description of a specific cruise route or a ship is suitable.</p>' +
    '<p>- This setting is best for displaying the widget on a cruise or ship description web page.</p>' +
    '<p><b>Destination</b>:</p>' +
    '<p>- Only excursions from different ports in a specific destination (e.g. Canary Islands) are displayed.</p>' +
    '<p>- In the following step 3 "Narrow down" you then have the option of displaying only the top excursions from different ports in your selected destination that we have marked.</p>',
  filterStepHelp1:
    '<p>Here you can make some additional settings.</p>' +
    '<p><b>Only excursions with the label:</b></p>' +
    '<p>- "My Shore Excursions" identifies selected excursions with appropriate tags:</p>' +
    '<p><ul><li>Top - our bestsellers</li></p>' +
    '<p><li>Sale - Excursions that are currently on sale</li></p>' +
    '<p><li>New - Excursions that have recently been added to the portfolio</li></p>' +
    '<p><li>Private - our exclusive excursions</li></p>' +
    '<p><li>On your own - Excursions without transfers and on-time back-to-ship guarantee</li></ul></p>' +
    '<p>Please note that if you select tags, the number of trips on the widget home page will be significantly reduced.</p>',
  filterStepHelp2:
    '<p><b>Show only a few products (3 - 5) and reload more with one click:</b></p>' +
    '<p>- Sometimes it can be a lot of trips. To save space on the website, you can show 3 to 5 products and hide others. With a button under the products, the customer can display all products.</p>',
  filterStepHelp3:
    '<p><b>Number of maximum excursions:</b></p>' +
    '<p>- Show only maximum number of trips.</p>',
  codeStepHelp:
    '<p>Copy this code and paste it into your website. If you are using a CMS (e.g. wordpress), please use the code editor to paste the code into the page. Otherwise this would only be output as text on the page.</p>',
};

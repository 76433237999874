<template>
  <div>
    <general-step v-show="step === 0" :form="widgetForm.generalStep" @next="next()" />

    <start-date-step
      v-show="step === 1"
      :form="widgetForm.startDateStep"
      :active-tab-start="activeTabStart"
      :cruise-type="widgetForm.generalStep.cruiseType"
      @activeTabStartChange="activeTabStartUpdate"
      @next="next()"
      @prev="prev()"
    />

    <filter-step
      v-show="step === 2"
      :form="widgetForm.filterStep"
      :active-tab-start="activeTabStart"
      @next="next()"
      @prev="prev()"
    />

    <code-step v-show="step === 3" :active-tab-start="activeTabStart" :form="widgetForm" @prev="prev()" />
  </div>
</template>

<script>
import CodeStep from './CodeStep';
import FilterStep from './FilterStep';
import GeneralStep from './GeneralStep';
import StartDateStep from './StartDateStep';
import HostNameService from './../services/HostNameService';

export default {
  name: 'FormWizard',
  components: { CodeStep, FilterStep, GeneralStep, StartDateStep },
  props: {
    step: {
      default: 0,
      type: Number,
    },
    defaultCustomerId: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: function() {
    return {
      activeTabStart: 0,
      widgetForm: {
        generalStep: {
          customerId: this.defaultCustomerId,
          channel: '',
          shopSelected: HostNameService.getShop(),
          cruiseType: 'sea',
          components: ['search', 'itinerary', 'products'],
          showSearch: true,
          showEyecatcher: true,
        },
        startDateStep: {
          widgetPortId: null,
          cruiseCompanyId: null,
          cruiseShipId: null,
          cruiseId: null,
          destination: '',
          cruiseShipName: '',
          cruiseDate: '',
          cruiseDuration: null,
          portIds: [],
          productIds: [],
        },
        filterStep: {
          tags: '',
          limit: null,
          shortProducts: false,
        },
      },
      langService: HostNameService,
    };
  },
  watch: {
    widgetForm: {
      handler() {
        this.$emit('changed', this.widgetForm);
      },
      deep: true,
    },
  },
  methods: {
    activeTabStartUpdate(data) {
      this.activeTabStart = data;
    },
    next() {
      if (this.activeTabStart === 3) {
        this.$emit('update:step', this.step + 2);
      } else {
        this.$emit('update:step', this.step + 1);
      }
    },
    prev() {
      if (this.activeTabStart === 3) {
        this.$emit('update:step', this.step - 2);
      } else {
        this.$emit('update:step', this.step - 1);
      }
    },
  },
};
</script>

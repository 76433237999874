<template>
  <el-form
    ref="startDateForm"
    :model="form"
    label-position="left"
    class="m-b-10"
  >
    <div class="start-date-step">
      <el-row>
        <el-col>
          <help-tooltip :content="$tc('startDateStepHelp')" />
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-row :gutter="15" class="m-b-25">
            <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
              <el-radio-group v-model="tabLocal">
                <el-radio-button :label="0" @click="tabLocal = 0">{{
                  $t("portSearch")
                }}</el-radio-button>
                <el-radio-button :label="1" @click="tabLocal = 1">{{
                  $t("cruiseSearch")
                }}</el-radio-button>
                <el-radio-button :label="2" @click="tabLocal = 2">{{
                  $t("destinationSearch")
                }}</el-radio-button>
                <el-radio-button :label="3" @click="tabLocal = 3">{{
                  $t("productsSearch")
                }}</el-radio-button>
              </el-radio-group>
            </el-col>
          </el-row>
          <el-row v-show="tabLocal === 0">
            <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
              <el-form-item :label="$tc('portSelect')" prop="widgetPortId" :rules="tabLocal === 0 ? rules.widgetPortId : {}" label-width="170px">
                <el-select
                  v-model="form.widgetPortId"
                  :placeholder="$tc('select')"
                  class="w-77"
                >
                  <el-option
                    v-for="item in ports"
                    :key="item.id"
                    :label="item.port"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-show="tabLocal === 1">
            <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
              <el-row>
                <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
                  <el-form-item
                    :label="$tc('cruiseCompanySelect')"
                    prop="cruiseCompanyId"
                    :rules="tabLocal === 1 ? rules.cruiseCompanyId : {}"
                    label-width="170px"
                  >
                    <el-select
                      v-model="form.cruiseCompanyId"
                      :placeholder="$tc('select')"
                      class="w-77"
                    >
                      <el-option
                        v-for="item in cruiseCompanies"
                        :key="item.id"
                        :label="item.company"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="form.cruiseCompanyId">
                <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
                  <el-form-item
                    :label="$tc('cruiseShipSelect')"
                    prop="cruiseShipId"
                    :rules="tabLocal === 1 ? rules.cruiseShipId : {}"
                    label-width="170px"
                  >
                    <el-select
                      v-model="form.cruiseShipId"
                      :placeholder="$tc('select')"
                      class="w-77"
                    >
                      <el-option
                        v-for="item in cruiseShips"
                        :key="item.id"
                        :label="item.ship"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="form.cruiseShipId">
                <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
                  <el-form-item :label="$tc('cruiseSelect')" label-width="170px">
                    <el-select
                      v-model="form.cruiseId"
                      :placeholder="$tc('select')"
                      class="w-77"
                    >
                      <el-option
                        v-for="item in cruiseData"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row v-show="tabLocal === 2">
            <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
              <el-form-item :label="$tc('destination')" prop="destination" :rules="tabLocal === 2 ? rules.destination : {}" label-width="170px">
                <el-select
                  v-model="form.destination"
                  :placeholder="$tc('select')"
                  class="w-77"
                >
                  <el-option
                    v-for="item in destinations"
                    :key="item.id"
                    :label="item.destination"
                    :value="item.destination"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-show="tabLocal === 3">
            <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
              <el-row>
                <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
                  <el-form-item :label="$tc('portsSelect')" prop="portIds" :rules="tabLocal === 3 ? rules.portIds : rules.withoutPortIds" label-width="170px">
                    <el-select
                      v-model="form.portIds"
                      :placeholder="$tc('select')"
                      class="w-77"
                      multiple
                    >
                      <el-option
                        v-for="item in ports"
                        :key="item.id"
                        :label="item.port"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="form.portIds.length > 0">
                <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24">
                  <el-form-item :label="$tc('productsSelect')" prop="productIds" :rules="tabLocal === 3 ? rules.productIds : {}" label-width="170px">
                    <el-checkbox-group v-model="form.productIds" class="products">
                      <el-checkbox v-for="product in products" :key="product.id" :label="product.id" class="product-item">{{ product.title }}</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <el-row type="flex" justify="end" gap="20">
      <el-button
        type="default"
        icon="el-icon-arrow-left"
        @click="prev"
      >{{ $t("previousStep") }}</el-button>
      <el-button
        type="primary"
        class="pull-right"
        @click="next"
      >{{ $t("nextStep") }}<i
        class="el-icon-arrow-right"
      /></el-button>

    </el-row>
  </el-form>
</template>
<script>
import DataService from './../api/SelectService';
import HelpTooltip from './HelpTooltip';

export default {
  name: 'StartDateStep',
  components: { HelpTooltip },
  props: {
    form: {
      type: Object,
      default() {
        return {};
      },
    },
    cruiseType: {
      type: String,
      default: 'sea',
    },
    activeTabStart: {
      type: Number,
      default: 0,
    },
  },
  data: function() {
    return {
      destinations: [],
      cruiseCompanies: [],
      cruiseShips: [],
      cruiseData: [],
      ports: [],
      products: [],
      rules: {
        widgetPortId: [
          {
            required: true,
            message: this.$i18n.t('selectedFieldsRequiredMessage'),
            trigger: 'change',
          },
        ],
        cruiseCompanyId: [
          {
            required: true,
            message: this.$i18n.t('selectedFieldsRequiredMessage'),
            trigger: 'change',
          },
        ],
        cruiseShipId: [
          {
            required: true,
            message: this.$i18n.t('selectedFieldsRequiredMessage'),
            trigger: 'change',
          },
        ],
        destination: [
          {
            required: true,
            message: this.$i18n.t('selectedFieldsRequiredMessage'),
            trigger: 'change',
          },
        ],
        portIds: [
          {
            type: 'array',
            required: true,
            message: this.$i18n.t('selectedFieldsRequiredMessage'),
            trigger: 'change',
          },
        ],
        withoutPortIds: [
          {
            required: false,
            type: 'array',
          },
        ],
        productIds: [
          {
            required: true,
            message: this.$i18n.t('selectedFieldsRequiredMessage'),
            trigger: 'change',
          },
        ],
      },
    };
  },
  computed: {
    tabLocal: {
      get: function() {
        return this.activeTabStart;
      },
      set: function(value) {
        this.resetForm();
        this.$emit('activeTabStartChange', value);
      },
    },
  },
  watch: {
    'cruiseType': async function() {
      if (this.cruiseType) {
        let type = this.cruiseType;
        if (this.cruiseType === 'all') {
          type = '';
        }
        this.ports = await DataService.getPorts(type);
        this.cruiseCompanies = await DataService.getCompanies(type);
        this.destinations = await DataService.getDestinations(type);
      }
    },
    'form.cruiseCompanyId': async function() {
      if (this.form.cruiseCompanyId) {
        this.cruiseShips = await DataService.getShip(this.form.cruiseCompanyId);
      }
      this.form.cruiseShipId = null;
      this.form.cruiseId = null;
    },
    'form.cruiseShipId': async function() {
      this.form.cruiseId = null;
      this.cruiseData = this.form.cruiseShipId ? await DataService.getCruise(this.form.cruiseShipId) : null;
      if (this.cruiseData !== null) {
        for (const key of Object.keys(this.cruiseData)) {
          const date = new Date(
            this.cruiseData[key].start_date,
          ).toLocaleDateString();
          this.cruiseData[key]['name'] =
            this.cruiseData[key].ship.ship +
            ' - ' +
            date +
            ' - ' +
            this.cruiseData[key].duration +
            ' ' +
            this.$i18n.t('days') +
            ' - ' +
            this.cruiseData[key].destination.destination;
        }
        this.form.cruiseShipName = this.cruiseData[0].ship.ship;
        this.form.cruiseDate = this.cruiseData[0].start_date;
        this.form.cruiseDuration = this.cruiseData[0].duration;
      }
    },
    'form.cruiseId': async function() {
      const data = this.cruiseData.find(el => el.id === this.form.cruiseId);
      this.form.cruiseShipName = data.ship.ship;
      this.form.cruiseDate = data.start_date;
      this.form.cruiseDuration = data.duration;
    },
    'form.portIds': async function() {
      this.form.productIds = [];
      if (this.form.portIds.length > 0) {
        this.products = await DataService.getProducts(this.form.portIds);
      }
    },
  },
  async created() {
    this.ports = await DataService.getPorts(this.form.cruiseType);
    this.cruiseCompanies = await DataService.getCompanies(this.form.cruiseType);
    this.destinations = await DataService.getDestinations(this.form.cruiseType);
  },
  methods: {
    next() {
      this.$refs.startDateForm.validate((validate) => {
        if (validate) {
          this.$emit('next');
          this.$emit('changed', this.form);
        }
      });
    },
    prev() {
      this.tabLocal = 0;
      this.$emit('prev');
      this.$emit('changed', this.form);
      this.resetForm();
    },
    resetForm() {
      this.$refs.startDateForm.resetFields();
      this.form.cruiseDate = '';
      this.form.cruiseDuration = '';
      this.form.cruiseShipName = '';
      this.form.productIds = [];
      this.form.portIds = [];
    },
  },
};
</script>

<template>
  <el-form
    ref="filterForm"
    :model="form"
    label-position="left"
    class="m-b-10"
  >
    <div class="filter-step">
      <el-row>
        <el-col>
          <help-tooltip :content="helpContent" />
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-row v-show="activeTabStart !== 1" :gutter="15">
            <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
              <el-form-item :label="$tc('tags')" prop="tags">
                <el-select v-model="form.tags" :placeholder="$tc('select')" clearable>
                  <el-option
                    v-for="item in tags"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-show="activeTabStart === 2" :gutter="15">
            <el-col :xl="5" :lg="5" :md="5" :sm="7" :xs="9">
              <el-form-item :label="$tc('limit')" label-width="100px" prop="limit">
                <el-input-number
                  v-model="form.limit"
                  controls-position="right"
                  size="small"
                  :placeholder="$tc('limit')"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="15">
            <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
              <el-form-item prop="shortProducts">
                <el-checkbox
                  v-model="form.shortProducts"
                  :label="$t('shortProducts')"
                  name="shortProducts"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <el-row type="flex" justify="end" gap="20">
      <el-button
        type="default"
        icon="el-icon-arrow-left"
        @click="prev"
      >{{ $t("previousStep") }}
      </el-button>
      <el-button
        type="primary"
        class="pull-right"
        @click="next"
      >{{ $t("nextStep") }}<i
        class="el-icon-arrow-right"
      /></el-button>

    </el-row>
  </el-form>
</template>

<script>
import HelpTooltip from './HelpTooltip';

export default {
  name: 'FilterStep',
  components: { HelpTooltip },
  props: {
    form: {
      type: Object,
      default() {
        return {};
      },
    },
    activeTabStart: {
      type: Number,
      default: 0,
    },
  },
  data: function() {
    return {
      tags: [
        { id: 'top', value: this.$i18n.t('top') },
        { id: 'sale', value: this.$i18n.t('sale') },
        { id: 'new', value: this.$i18n.t('new') },
        { id: 'privat', value: this.$i18n.t('private') },
        { id: 'onyourown', value: this.$i18n.t('onYourOwn') },
      ],
    };
  },
  computed: {
    helpContent: function() {
      if (this.activeTabStart === 0) {
        return this.$i18n.t('filterStepHelp1');
      } else if (this.activeTabStart === 1) {
        return (
          this.$i18n.t('filterStepHelp1') + this.$i18n.t('filterStepHelp2')
        );
      } else if (this.activeTabStart === 2) {
        return (
          this.$i18n.t('filterStepHelp1') + this.$i18n.t('filterStepHelp3')
        );
      }
      return '';
    },
  },
  methods: {
    next() {
      this.$emit('next');
      this.$emit('changed', this.form);
    },
    prev() {
      this.$emit('prev');
      this.$emit('changed', this.form);
      this.$refs.filterForm.resetFields();
    },
  },
};
</script>

<template>
  <div>
    <el-tooltip
      class="pulse pull-right"
      effect="light"
      placement="right"
      popper-class="tooltip-help"
    >
      <template v-slot:content>
        <div v-html="content" />
      </template>
      <i class="el-icon-question" />
    </el-tooltip>
  </div>
</template>
<script>
export default {
  name: 'HelpTooltip',
  props: {
    content: {
      type: String,
      default: '',
    },
  },
};
</script>

import request from '@/utils/request';
import HostNameService from '@/services/HostNameService';

export default {

  /**
   * Returns the base URL for the API.
   * The base URL is constructed by appending the API version to the URL
   * obtained from the HostNameService.
   *
   * @returns {string} The base URL for the API.
   */
  baseURL: HostNameService.getUrlApi() + '/cruise/v1/',

  /**
   * Retrieves the URL for the booking API.
   *
   * @returns {string} The URL for the booking API.
   */
  bookingURL: HostNameService.getUrlApi() + '/booking/v1/',

  /**
   * Get ports based on type
   *
   * @param {string} [type='sea', type='river'] The type of ports to retrieve (default: 'sea')
   * @returns {Promise} - A promise that resolves to the response from the API or rejects with an error
   */
  getPorts: function(type = 'sea') {
    let url = this.baseURL + 'ports';
    if (type !== '') {
      url = url + '?type=' + type;
    }
    return new Promise((resolve, reject) => {
      request({
        url: url,
        method: 'get',
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * Retrieve companies of specified type
   *
   * @param {string} [type='sea', type='river'] - The type of companies to retrieve
   * @returns {Promise} - A promise that resolves with the response or rejects with an error
   */
  getCompanies: function(type = 'sea') {
    let url = this.baseURL + 'companies';
    if (type !== '') {
      url = url + '?type=' + type;
    }
    return new Promise((resolve, reject) => {
      request({
        url: url,
        method: 'get',
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * Retrieves ship data for a given cruise company ID.
   *
   * @param {number} cruiseCompanyId - The ID of the cruise company.
   * @return {Promise} - A promise that resolves with the ship data or rejects with an error.
   */
  getShip: function(cruiseCompanyId) {
    return new Promise((resolve, reject) => {
      request({
        url: this.baseURL + 'companies/' + cruiseCompanyId + '/ships',
        method: 'get',
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * Retrieves cruise information by cruise ship ID.
   *
   * @param {string|number} cruiseShipId - The ID of the cruise ship.
   * @returns {Promise} - A Promise that resolves with the cruise information or rejects with an error.
   */
  getCruise: function(cruiseShipId) {
    return new Promise((resolve, reject) => {
      request({
        url: this.baseURL + 'cruises/find-by-shipid?id=' + cruiseShipId,
        method: 'get',
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * Retrieves destinations based on the specified type.
   * If no type is provided, it defaults to 'sea'.
   *
   * @param {string} [type='sea', type='river'] - The type of destinations to retrieve.
   *
   * @returns {Promise} A Promise that resolves to the response containing the destinations or rejects with an error.
   */
  getDestinations: function(type = 'sea') {
    let url = this.baseURL + 'destinations';
    if (type !== '') {
      url = url + '?type=' + type;
    }
    return new Promise((resolve, reject) => {
      request({
        url: url,
        method: 'get',
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /**
   * Retrieves products for a specific port ID.
   *
   * @param {string} portId - The port ID for which to retrieve the products.
   * @returns {Promise} - A promise that resolves with the response from the API or rejects with an error.
   */
  getProducts: function(portId) {
    return new Promise((resolve, reject) => {
      request({
        url: this.bookingURL + 'products?ports=' + portId,
        method: 'get',
      })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
